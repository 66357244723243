import {
  PLACEFLAG_CLINIC_CASE_TAB,
  PLACEFLAG_COURSE_TAB,
  PLACEFLAG_CROWDFUNDING,
  PLACEFLAG_LECTOR_LIST,
  PLACEFLAG_LIBRARY,
  PLACEFLAG_MAIN,
  PLACEFLAG_COURSE_CARDIO,
} from './analytic-data'
import { STATIC_URL } from './data'

enum SEOType {
  TITLE = 'TITLE',
  DESCRIPTION = 'DESCRIPTION',
  LINK = 'LINK',
  IMG = 'IMG',
  PLACE = 'PLACE',
}

const URL = 'https://medpoint.pro'

const BASE_TITLE =
  'Пройти НМО обучение для врачей онлайн на платформе Medpoint.pro | Курсы и вебинары НМО для врачей'
const BASE_DESCRIPTION =
  'Бесплатные и платные вебинары и курсы НМО для врачей, записи лекций, результаты исследований и клинические рекомендации в одном месте! Пройти обучение можно в очном и дистанционном формате, получайте баллы НМО онлайн через личный кабинет.'
const MEDPOINT = 'Medpoint'

const BaseSEO = {
  [SEOType.TITLE]: BASE_TITLE,
  [SEOType.DESCRIPTION]: BASE_DESCRIPTION,
  [SEOType.LINK]: URL,
  [SEOType.IMG]: `${URL}${STATIC_URL}/medpoint_new_main_seo.png`,
}

const IndexPageSEO = {
  [SEOType.TITLE]: BASE_TITLE,
  [SEOType.DESCRIPTION]: BASE_DESCRIPTION,
  [SEOType.LINK]: URL,
  [SEOType.IMG]: `${URL}${STATIC_URL}/medpoint_new_main_seo.png`,
  [SEOType.PLACE]: PLACEFLAG_MAIN,
}

const MainPageSEO = {
  TITLE: 'Ближайшие вебинары с НМО и новые материалы',
  DESCRIPTION: BASE_DESCRIPTION,
  LINK: URL,
  type: 'main',
}

const MelodyOfHeartSEO = {
  [SEOType.TITLE]: `Мелодия сердца`,
  [SEOType.DESCRIPTION]:
    'Первая Конференция Родионова Антона Владимировича по кардиологии "Мелодия сердца", 6 апреля 2025',
  [SEOType.LINK]: `${URL}/melody-heart`,
  [SEOType.PLACE]: 'melody-heart',
}

const HumanAgingSEO = {
  [SEOType.TITLE]: `Старение человека`,
  [SEOType.DESCRIPTION]: 'Старение человека',
  [SEOType.LINK]: `${URL}/stareniecheloveka-2025`,
}

const TechnoloiiDolgoletiaSeo = {
  [SEOType.TITLE]: `Первый Форум "Технологии долголетия"`,
  [SEOType.DESCRIPTION]:
    'Первый Форум "Технологии долголетия" - главная площадка для обсуждения научных, социальных и экономических аспектов старения совместно с ведущими экспертами. ',
}

const TechnoloiiDolgoletiaTezisSeo = {
  [SEOType.TITLE]: `Тезисы "Технологии долголетия"`,
  [SEOType.DESCRIPTION]: 'Тезисы "Технологии долголетия" ',
}

const MoscowGeriatrySEO = {
  [SEOType.TITLE]: `Образовательные школы Московского отделения`,
  [SEOType.DESCRIPTION]: 'Образовательные школы Московского отделения РАГГ',
  [SEOType.LINK]: `${URL}/moscow-geriatry`,
}

const AboutPageSEO = {
  [SEOType.TITLE]: 'О нас | Medpoint.pro',
  [SEOType.DESCRIPTION]:
    'Medpoint - платформа для дистанционного обучения врачей всех специальностей при поддержке РНИМУ им. Пирогова',
  [SEOType.LINK]: `${URL}/about`,
}

const WebinarWantSEO = {
  [SEOType.TITLE]: `Закладки вебинаров для врачей в записи на ${MEDPOINT}`,
  [SEOType.DESCRIPTION]: 'Бесплатные вебинары для врачей в системе НМО.',
  [SEOType.LINK]: `${URL}/webinar/wantSee`,
}

const WebinarPastSEO = {
  [SEOType.TITLE]: `Вебинары для врачей в записи на ${MEDPOINT}`,
  [SEOType.DESCRIPTION]:
    'Бесплатные вебинары для врачей в системе НМО. Более 20 вебинаров ежемесячно.',
  [SEOType.LINK]: `${URL}/webinar`,
}

const LibraryArticleSEO = {
  [SEOType.TITLE]: `Научные медицинские статьи для врачей на ${MEDPOINT}`,
  [SEOType.DESCRIPTION]:
    'Читайте современные научные статьи для врачей, проверенные врачами, новости медицины и здравоохранения от ведущих специалистов на портале Medpoint',
  [SEOType.LINK]: `${URL}/library/article`,
  [SEOType.PLACE]: PLACEFLAG_LIBRARY,
}

const LibraryVideoSEO = {
  [SEOType.TITLE]: `Бесплатные медицинские лекции для врачей онлайн`,
  [SEOType.DESCRIPTION]:
    'Онлайн лекции для врачей, видео по медицине для всех уровней: от начинающих специалистов и до экспертов',
  [SEOType.LINK]: `${URL}/library/video`,
  [SEOType.PLACE]: PLACEFLAG_LIBRARY,
}

const LibraryClinicSEO = {
  [SEOType.TITLE]: `Клинические рекомендации для врачей 2024`,
  [SEOType.DESCRIPTION]:
    'Рубрикатор клинических рекомендаций 2024 для взрослых и детей на сайте Medpoint.pro',
  [SEOType.LINK]: `${URL}/library/clinic`,
  [SEOType.PLACE]: PLACEFLAG_LIBRARY,
}

const LibraryPodcastSEO = {
  [SEOType.TITLE]: `Подкасты про медицину для врачей`,
  [SEOType.DESCRIPTION]:
    'Бесплатные подкасты по медицине для врачей на русском языке от ведущих экспертов',
  [SEOType.LINK]: `${URL}/library/podcast`,
  [SEOType.PLACE]: PLACEFLAG_LIBRARY,
}

const ProfilePageSEO = {
  [SEOType.TITLE]: `Личный кабинет на ${MEDPOINT}`,
  [SEOType.DESCRIPTION]: 'Управляйте вашими подписками и следите за историей получения баллов НМО',
  [SEOType.LINK]: `${URL}/profile`,
}

const LecturePageSEO = {
  [SEOType.TITLE]: `Лекторы ${MEDPOINT}`,
  [SEOType.DESCRIPTION]:
    'Информация о лекторах: регалии, научные интересы и их материалы на Medpoint',
  [SEOType.LINK]: `${URL}/lecture`,
  [SEOType.PLACE]: PLACEFLAG_LECTOR_LIST,
}

const BotAiSEO = {
  [SEOType.TITLE]: `AI-Medicus`,
  [SEOType.DESCRIPTION]: 'Бот для врачей AI-Medicus',
  [SEOType.LINK]: `${URL}/ai-medicus`,
}

const BotAiLandingSEO = {
  [SEOType.TITLE]: `AI-Medicus`,
  [SEOType.DESCRIPTION]: 'Информация для врачей о боте AI-Medicus',
  [SEOType.LINK]: `${URL}/ai-medicus/landing`,
}

const NMOPageSEO = {
  [SEOType.TITLE]: 'Баллы НМО для врачей – что это и как набрать',
  [SEOType.DESCRIPTION]:
    'Рассказываем что такое непрерывное медицинское образование, как набрать баллы НМО для врачей, сколько нужно баллов НМО для аккредитации медиков',
  [SEOType.LINK]: `${URL}/nmo`,
}

const NMOActivationSEO = {
  [SEOType.TITLE]: 'Как добавить баллы НМО?',
  [SEOType.DESCRIPTION]:
    'Рассказываем как добавить баллы НМО за вебинары на портал непрерывного медицинского образования',
  [SEOType.LINK]: `${URL}/nmo/activation`,
}

const NMOAccreditationSEO = {
  [SEOType.TITLE]: 'Как получить налоговый вычет за курсы НМО врачу?',
  [SEOType.DESCRIPTION]: 'Инструкция "Как получить налоговый вычет за курсы НМО врачу?',
  [SEOType.LINK]: `${URL}/nmo/accreditation`,
}

const NMOQualificationSEO = {
  [SEOType.TITLE]: 'Способы оплаты курсов для врачей',
  [SEOType.DESCRIPTION]: 'Инструкцуия про способы оплаты курсов для повышения квалификации',
  [SEOType.LINK]: `${URL}/nmo/qualification`,
}

const NMORequestSEO = {
  [SEOType.TITLE]: 'Как подать заявку на портале НМО на обучение',
  [SEOType.DESCRIPTION]: 'Рассказываем по шагам как подать заявку врачу на портале НМО',
  [SEOType.LINK]: `${URL}/nmo/request`,
}

const NMOReportSEO = {
  [SEOType.TITLE]: 'Отчет о профессиональной деятельности',
  [SEOType.DESCRIPTION]:
    'Рассказываем как составить врачу отчет о профессиональной деятельности для прохождения периодической аккредитации.',
  [SEOType.LINK]: `${URL}/nmo/report`,
}

const PaymentPageSEO = {
  [SEOType.TITLE]: 'Оплата на Medpoint',
  [SEOType.DESCRIPTION]:
    'Информация о существующих способах оплаты обучения на образовательной платформе Medpoint',
  [SEOType.LINK]: `${URL}/payment`,
}

const SearchPageSEO = {
  [SEOType.TITLE]: 'Поиск на Medpoint',
  [SEOType.LINK]: `${URL}/search`,
}

const ClinicalCasePageSEO = {
  [SEOType.TITLE]: 'Медицинские клинические случаи - онлайн разбор болезни пациента',
  [SEOType.DESCRIPTION]:
    'Анализ клинических случаев заболеваний. Вопросы врачей о подборе терапии и назначении обследований пациентов на портале Medpoint',
  [SEOType.LINK]: `${URL}/clinical-case`,
  [SEOType.PLACE]: PLACEFLAG_CLINIC_CASE_TAB,
}

const CoursePageSEO = {
  [SEOType.TITLE]: `Курсы НМО (непрерывное медицинское образование)`,
  [SEOType.DESCRIPTION]:
    'Программы повешения квалификации в системе непрерывного медицинского образования. Дистанционное обучение на курсах 18/36 НМО',
  [SEOType.LINK]: `${URL}/program`,
  [SEOType.PLACE]: PLACEFLAG_COURSE_TAB,
}

const AboutOrganizationPageSEO = {
  [SEOType.TITLE]: 'Сведения об образовательной организации',
  [SEOType.LINK]: `${URL}/about-organization`,
}

const CongressSEO = {
  [SEOType.TITLE]: `Конгресс «Управление старением»`,
  [SEOType.DESCRIPTION]:
    'Уникальное мероприятие, объединившее научный потенциал ведущих специалистов в области гематологии и гериатрии: Московский клинический научный центр и Российский геронтологический научно-клинический центр',
  [SEOType.LINK]: `${URL}/congress`,
}

const StudentsSEO = {
  [SEOType.TITLE]: `Всероссийская олимпиада по геронтологии и гериатрии`,
  [SEOType.DESCRIPTION]:
    'Уникальная возможность погрузиться в мир геронтологии и получить опыт решения нестандартных клинических задач',
  [SEOType.LINK]: `${URL}/students`,
}

const PartnersPageSEO = {
  [SEOType.TITLE]: `Наши партнеры`,
  [SEOType.DESCRIPTION]:
    'Список компаний/ассоциаций партнеров, которые вносят свой вклад в развитие профессионального портала для врачей Medpoint',
  [SEOType.LINK]: `${URL}/partners`,
}

const CrowdFundingPageSEO = {
  [SEOType.TITLE]: `Помогаем медикам в учебе и жизни`,
  [SEOType.DESCRIPTION]:
    'Medpoint присоединяется к фонду «ВБлагодарность» для помощи медикам, оказавшимся в трудной жизненной ситуации',
  [SEOType.LINK]: `${URL}/pomoshmedikam`,
  [SEOType.PLACE]: PLACEFLAG_CROWDFUNDING,
}

const GamePromoSEO = {
  [SEOType.TITLE]: `Бонусная программа Medpoint`,
  [SEOType.DESCRIPTION]: 'Смотрите вебинары, копите бонусы и обменивайте их на призы',
  [SEOType.LINK]: `${URL}/game/promo`,
}

const GamePageSEO = {
  [SEOType.TITLE]: `Бонусная программа Medpoint`,
  [SEOType.DESCRIPTION]:
    'Получать Медкоины можно за просмотр вебинаров, чтение статей и многое другое. А тратить - на призы от нас и наших партнеров',
  [SEOType.LINK]: `${URL}/game`,
}

const GameHistorySEO = {
  [SEOType.TITLE]: `История начислений Медкоинов`,
  [SEOType.DESCRIPTION]: 'Следите за начислением и списанием Медкоинов',
  [SEOType.LINK]: `${URL}/game/history`,
}

const DozatorSEO = {
  [SEOType.TITLE]: `Дозатор лекарств`,
  [SEOType.DESCRIPTION]:
    'Здесь можно выбрать группу интересующих препаратов и приступить к Дозированию',
  [SEOType.LINK]: `${URL}/dozator`,
}

const MapSiteSEO = {
  [SEOType.TITLE]: `Карта сайта Medpoint.pro`,
  [SEOType.DESCRIPTION]: 'Здесь можно посмотреть основные разделы сайта',
  [SEOType.LINK]: `${URL}/map-site`,
}

const WebinarTargetSEO = (genForm: string, doctorForm: string, link: string) => ({
  [SEOType.TITLE]: `Вебинары по ${genForm} с баллами НМО`,
  [SEOType.DESCRIPTION]: `Бесплатные вебинары по ${genForm} с баллами НМО, онлайн вебинары для врачей ${doctorForm} на портале Medpoint`,
  [SEOType.LINK]: `${URL}/webinar/target/${link}`,
})

const VideoTargetSEO = (genForm: string, doctorForm: string, link: string) => ({
  [SEOType.TITLE]: `Лекции по ${genForm} для врачей на Medpoint`,
  [SEOType.DESCRIPTION]: `Смотрите видео и лекции по ${genForm} для врачей, видеолекции для ${doctorForm} от ведущих специалистов в мире медицины на портале Medpoint`,
  [SEOType.LINK]: `${URL}/library/video/target/${link}`,
})

const ArticleTargetSEO = (genForm: string, doctorForm: string, link: string) => ({
  [SEOType.TITLE]: `Научные статьи по ${genForm} для врачей на Medpoint`,
  [SEOType.DESCRIPTION]: `Научные статьи по ${genForm} для врачей, полезные медицинские статьи для ${doctorForm} на портале Medpoint`,
  [SEOType.LINK]: `${URL}/library/article/target/${link}`,
})

const SanofiInteractiveSEO = {
  [SEOType.TITLE]: 'Руководство к борьбе со стрессом в интерактивном формате',
  [SEOType.DESCRIPTION]: `Решение разных профессиональных задач может быть связано с возникновением симптомов стресса и тревожности. Часто ли вы останавливаетесь и уделяете должное внимание на своему эмоциональному благополучию? Предлагаем выдохнуть и оценить свое состояние, а также выбрать путь преодоления стресса`,
  [SEOType.LINK]: `${URL}/sanofi/interactive`,
}

const SanofiInteractiveNYSEO = {
  [SEOType.TITLE]: 'Новогодний медицинский квест: по следам дефицита магния',
  [SEOType.DESCRIPTION]: `Как давно вы смотрели в микроскоп? Предлагаем пройти путь нарушения гомеостаза от изучения клетки до органного уровня. Интерактивное путешествие по системам человека – в специальном новогоднем формате!`,
  [SEOType.LINK]: `${URL}/sanofi/interactive-ny`,
}

const SanofiInteractiveCC1SEO = {
  [SEOType.TITLE]: 'Клинический кейс в интерактивном формате: необычный случай пациентки',
  [SEOType.DESCRIPTION]: `Часто бывает, что симптоматика простая, но неспецифическая. Предыдущие стратегии лечения не подошли, и пациентка обращается к Вам. Необходимо дообследовать, однако стандартные анализы уже готовы и понятнее не становится… Какая терапия оказалась эффективной – проверьте себя в интерактиве, основанном на реальном клиническом опыте коллег!`,
  [SEOType.LINK]: `${URL}/sanofi/interactive-clin-case-1`,
}

const SanofiInteractiveCC2SEO = {
  [SEOType.TITLE]: 'Интерактивный клинический кейс: пациент, которому «ничего не подходит»',
  [SEOType.DESCRIPTION]: `Очень часто Вы – не первый врач, к которому обратился пациент со своей проблемой. Симптомы – понятны, стратегия терапии Вами определена. Но предыдущий опыт лечения больного ставит свои ограничения: как поступить с пациентом, которому не подошли классические схемы терапии? Получите интерактивный клинический опыт, основанный на реальном случае коллеги-невролога.`,
  [SEOType.LINK]: `${URL}/sanofi/interactive-clin-case-2`,
}

const CourseGoldStandardSEO = {
  [SEOType.TITLE]: 'Курс для врачей по кардиологии «Золотой стандарт»',
  [SEOType.DESCRIPTION]: `Курс 36 баллов НМО для врачей, обучение на основе курса от крупнейшего медицинского центра в мире - Mayo Clinic, адаптированного под российскую клиническую практику`,
  [SEOType.LINK]: `${URL}/program/cardio-gold-standard`,
  [SEOType.PLACE]: PLACEFLAG_COURSE_CARDIO,
}
const CourseNeuroDisordersSEO = {
  [SEOType.TITLE]: 'Курс «Когнитивные расстройства в гериатрической практике»',
  [SEOType.DESCRIPTION]: `Курс для врачей 18 НМО “Когнитивные расстройства в гериатрической практике”`,
  [SEOType.LINK]: `${URL}/program/neuro-cognitive-disorders`,
}
const CourseGeriatricsSEO = {
  [SEOType.TITLE]: 'Современная гериатрия для практикующих врачей',
  [SEOType.DESCRIPTION]: `Курс для врачей, аккредитованный на 144 балла НМО`,
  [SEOType.LINK]: `${URL}/program/modern-geriatrics`,
}

const CourseGeriatricsBasicSEO = {
  [SEOType.TITLE]: 'Курс для практикующих врачей по гериатрии 18 НМО',
  [SEOType.DESCRIPTION]: `Курс для врачей "Основы гериатрии", аккредитованный на 18 баллов НМО.`,
  [SEOType.LINK]: `${URL}/program/geriatrics-basic`,
}

const CourseOsteporosisSEO = {
  [SEOType.TITLE]: 'Остеопороз: современные подходы к диагностике и лечению',
  [SEOType.DESCRIPTION]: `Курс для практикующих врачей аккредитованные на 18 баллов НМО`,
  [SEOType.LINK]: `${URL}/program/osteoporosis`,
}

const CourseGastrointestinalSEO = {
  [SEOType.TITLE]:
    'Заболевания желудочно-кишечного тракта: современные подходы к диагностике и лечению',
  [SEOType.DESCRIPTION]: `Курс для практикующих врачей аккредитованные на 18 баллов НМО`,
  [SEOType.LINK]: `${URL}/program/gastrointestinal-diseases`,
}

const CourseSomnologySEO = {
  [SEOType.TITLE]: 'Расстройства сна: современные подходы к диагностике и лечению',
  [SEOType.DESCRIPTION]: `Курс для практикующих врачей (18 НМО).`,
  [SEOType.LINK]: `${URL}/program/somnology`,
}

const CourseModernMethodsDmpSEO = {
  [SEOType.TITLE]: 'Современные методики дистанционного мониторинга пациентов',
  [SEOType.DESCRIPTION]:
    'Обучение, основанное на практическом опыте внедрения передовых технологий дистанционного медицинского наблюдения за состоянием здоровья пациентов в государственном здравоохранении на территории 69 субъектов РФ.',
  [SEOType.LINK]: `${URL}/program/modern-methods-dmp`,
}

const MayolySEO = {
  [SEOType.TITLE]: 'PRO.UPDATE – проект о новостях и практических решениях в гастроэнтерологии',
  [SEOType.DESCRIPTION]: `Проект PRO.UPDATE объединил актуальную информацию о последних достижениях медицины и эффективных решениях в диагностике и терапии пациентов с заболеваниями желудочно-кишечного тракта в помощь практикующему врачу от лидеров мнения в области гастроэнтерологии`,
  [SEOType.LINK]: `${URL}/mayoly`,
}

const ElidelSEO = {
  [SEOType.TITLE]: 'ЗАБОТА О ГЛАВНОМ - образовательный проект об атопическом дерматите',
  [SEOType.DESCRIPTION]: `Приглашаем принять участие в новом образовательном проекте для педиатров, дерматологов и аллергологов-иммунологов`,
  [SEOType.LINK]: `${URL}/viatris`,
}

const DonaSEO = {
  [SEOType.TITLE]: 'Точка опоры - информационно-образовательный проект',
  [SEOType.DESCRIPTION]: `Приглашаем принять участие в проекте о современном подходе к ведению пациентов с артрозом и другими дегенеративными заболеваниями опорно-двигательного аппарата`,
  [SEOType.LINK]: `${URL}/viatris/tochkaopory`,
}

const ViatrisClinCase1SEO = {
  [SEOType.TITLE]: 'Липидснижающие нутрицевтики',
  [SEOType.DESCRIPTION]: `Применение нутрицевтиков на основе красного дрожжевого риса у пациентов с дислипидемией с низким и умеренным сердечно-сосудистым риском.`,
  [SEOType.LINK]: `${URL}/viatris/clin-case1`,
}

const ViatrisClinCase2SEO = {
  [SEOType.TITLE]: 'Липидснижающие нутрицевтики',
  [SEOType.DESCRIPTION]: `Применение нутрицевтиков на основе красного дрожжевого риса у пациентов с дислипидемией с низким и умеренным сердечно-сосудистым риском.`,
  [SEOType.LINK]: `${URL}/viatris/clin-case2`,
}

const ViatrisClinCase3SEO = {
  [SEOType.TITLE]: '',
  [SEOType.DESCRIPTION]: ``,
  [SEOType.LINK]: `${URL}/viatris/clin-case3`,
}

const ViatrisClinCase4SEO = {
  [SEOType.TITLE]: '',
  [SEOType.DESCRIPTION]: ``,
  [SEOType.LINK]: `${URL}/viatris/clin-case4`,
}

const MedpulseSEO = {
  [SEOType.TITLE]: 'MedPulse - клинические случаи',
  [SEOType.DESCRIPTION]: `Сервис для практикующих врачей, где каждый может разместить клин. случай и получить мнение коллег.`,
  [SEOType.LINK]: `${URL}/medpulse`,
}

const CorporatePageSEO = {
  [SEOType.TITLE]: `Корпоративное обучение врачей с баллами НМО`,
  [SEOType.DESCRIPTION]:
    'Medpoint - партнер клиник, аккредитация в системе непрерывного медицинского образования (НМО) полностью на нашей стороне.',
  [SEOType.LINK]: `${URL}/corporate`,
}

const CalculatorGeriatricStatus = {
  [SEOType.TITLE]: 'Шкалы для оценки состояния пациента',
  [SEOType.DESCRIPTION]: `Шкалы для всесторонней гериатрической оценки пациентов.`,
  [SEOType.LINK]: `${URL}/calculators/geriatric-status`,
}

const CalculatorSomaticStatus = {
  [SEOType.TITLE]: 'Шкалы для оценки состояния пациента',
  [SEOType.DESCRIPTION]: `Шкалы для всесторонней гериатрической оценки пациентов.`,
  [SEOType.LINK]: `${URL}/calculators/somatic-status`,
}

const CalculatorAnticholergicStatus = {
  [SEOType.TITLE]: 'Антихолинергическая нагрузка',
  [SEOType.DESCRIPTION]: `Шкала расчета антихолинергической нагрузки`,
  [SEOType.LINK]: `${URL}/calculators/anticholinergic`,
}

const ViartisInteractive1SEO = {
  [SEOType.TITLE]:
    'Клинический случай коморбидного пациента с диабетической полинейропатией и когнитивной дисфункцией: назначаем лечение',
  [SEOType.DESCRIPTION]: `Приглашаем Вас погрузиться в интерактивный клинический кейс, разобраться с клинической картиной и выбрать для коморбидного пациента комбинированную терапию , где каждый компонент должен быть обоснован.`,
  [SEOType.LINK]: `${URL}/interactive/1`,
}

const GeriatricsSchoolSEO = {
  [SEOType.TITLE]: 'Школа гериатрии "Ключ к медицине"',
  [SEOType.DESCRIPTION]: `Образовательная программа для врачей, разработанная ведущими экспертами в области гериатрии, эндокринологии, кардиологии и неврологии.`,
  [SEOType.LINK]: `${URL}/geriatrics-school`,
}

const VoiceOfPracticeSEO = {
  [SEOType.TITLE]: `Голос практики: врачи для врачей | Реальные клинические случаи | Medpoint`,
  [SEOType.DESCRIPTION]:
    'Станьте участником проекта "Голос практики" от Medpoint — первой площадки, где практикующие врачи делятся реальным опытом. Поделитесь своими клиническими случаями и выиграйте 5000₽.',
  [SEOType.LINK]: `${URL}/voice-of-practice`,
  [SEOType.PLACE]: 'voice-of-practice',
}

export {
  URL,
  SEOType,
  BaseSEO,
  IndexPageSEO,
  MainPageSEO,
  MapSiteSEO,
  AboutPageSEO,
  WebinarPastSEO,
  WebinarWantSEO,
  LibraryArticleSEO,
  LibraryVideoSEO,
  LibraryClinicSEO,
  LibraryPodcastSEO,
  ProfilePageSEO,
  LecturePageSEO,
  NMOPageSEO,
  NMOActivationSEO,
  NMORequestSEO,
  NMOReportSEO,
  NMOAccreditationSEO,
  PaymentPageSEO,
  PartnersPageSEO,
  ClinicalCasePageSEO,
  CoursePageSEO,
  AboutOrganizationPageSEO,
  SearchPageSEO,
  CongressSEO,
  StudentsSEO,
  CrowdFundingPageSEO,
  WebinarTargetSEO,
  VideoTargetSEO,
  ArticleTargetSEO,
  GamePageSEO,
  GamePromoSEO,
  GameHistorySEO,
  DozatorSEO,
  SanofiInteractiveSEO,
  SanofiInteractiveNYSEO,
  SanofiInteractiveCC1SEO,
  SanofiInteractiveCC2SEO,
  CourseGoldStandardSEO,
  BotAiSEO,
  BotAiLandingSEO,
  CourseOsteporosisSEO,
  CourseNeuroDisordersSEO,
  CourseGeriatricsSEO,
  CourseSomnologySEO,
  CorporatePageSEO,
  MayolySEO,
  ElidelSEO,
  DonaSEO,
  ViatrisClinCase1SEO,
  MedpulseSEO,
  NMOQualificationSEO,
  CalculatorGeriatricStatus,
  CalculatorSomaticStatus,
  CalculatorAnticholergicStatus,
  CourseGastrointestinalSEO,
  ViartisInteractive1SEO,
  ViatrisClinCase2SEO,
  ViatrisClinCase3SEO,
  ViatrisClinCase4SEO,
  CourseGeriatricsBasicSEO,
  MoscowGeriatrySEO,
  GeriatricsSchoolSEO,
  CourseModernMethodsDmpSEO,
  HumanAgingSEO,
  TechnoloiiDolgoletiaSeo,
  TechnoloiiDolgoletiaTezisSeo,
  MelodyOfHeartSEO,
  VoiceOfPracticeSEO,
}
